import { extendTheme } from "@chakra-ui/react"
import Color from "color"

const COLOR = {
  PRIMARY: "#0053cc",
  SECONDARY: "#00d4ff",
  BG: {
    DARKER: "#111e36",
    DARK: "#0f2540",
    MAIN: "#f6f9fc",
  },
  FONT: {
    LIGHT: "#adbdcc",
    DARK: "#0a2540",
    MAIN: "#425466",
  },
}

const theme = {
  components: {
    Button: {
      baseStyle: {
        rounded: "full",
        py: "5",
      },
      variants: {
        primary: {
          bg: "primary.main",
          color: "white",
          _hover: {
            bg: "primary.dark",
          },
        },
      },
    },
    Container: {
      baseStyle: {
        maxW: "1200px",
        px: "4",
      },
    },
    Link: {
      variants: {
        nav: {
          color: "primary.main",
          fontWeight: "medium",
          _hover: {
            color: "primary.dark",
          },
        },
      },
    },
  },
  colors: {
    primary: {
      dark: Color(COLOR.PRIMARY).darken(0.2).toString(),
      main: COLOR.PRIMARY,
    },
    secondary: {
      main: COLOR.SECONDARY,
    },
    bg: {
      darker: COLOR.BG.DARKER,
      dark: COLOR.BG.DARK,
      main: COLOR.BG.MAIN,
    },
    font: {
      light: COLOR.FONT.LIGHT,
      dark: Color(COLOR.FONT.DARK).lighten(0.1).toString(),
      main: COLOR.FONT.MAIN,
    },
  },
  fonts: {
    heading: `'Poppins', san-serif`,
    body: `'Poppins', san-serif`,
  },
}

export default extendTheme(theme)
