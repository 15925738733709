/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import "@fontsource/poppins/100-italic.css"
import "@fontsource/poppins/100.css"
import "@fontsource/poppins/200-italic.css"
import "@fontsource/poppins/200.css"
import "@fontsource/poppins/300-italic.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500-italic.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600-italic.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700-italic.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/800-italic.css"
import "@fontsource/poppins/800.css"
import "@fontsource/poppins/900-italic.css"
import "@fontsource/poppins/900.css"

import RootElement from "./src/components/RootElement"

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>
}
